import { noop } from '@vueuse/core';
import { Vector4 } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';

import { useViewportResize } from '@resn/gozer-vue';
import { useLoaderComplete } from '@resn/gozer-vue/loading';

import useThreeAnimations from './useThreeAnimations';
import { useThreeHelpers } from '~/composables/useThreeHelpers';
import { LAYER_BG, LAYER_FG } from '~/core/constants';

// Frame rate control constants
const TARGET_FPS = 40; // Target frame rate for performance optimization
const FRAME_INTERVAL = 1000 / TARGET_FPS; // Time between frames in milliseconds

const useThreeMainScene = ({
    id = 'SceneMain',
    composer = null,
    addTo = true,
    active = ref(true),
    enableOrbit = false,
    enableHelpers = false,
    assets = null,
    cbSceneReady = noop,
}) => {
    const { renderer, camera: cameraDefault, orthoCamera, scene } = inject('renderer');
    cameraDefault.name = 'CameraDefault';

    // const scene = useThreeScene();
    scene.name = id;

    let camera = cameraDefault;
    let cameraScene;

    const isSceneReady = ref(false);
    // const isSandbox = inject('sandbox', false);

    let orbit;
    onMounted(() => {
        startFrameLoop();
    });
    const vViewOffset = new Vector4();

    const { addCameraHelpers, refreshHelpers, toggleHelpers } = useThreeHelpers(scene);
    const { setupAnimations, updateAnimations, getAnimations, getAnimation } = useThreeAnimations();
    const viewport = useViewportResize(({ width, height }) => resize({ width, height }), true);

    const canRender = computed(() => active.value);
    // const canRender = computed(() => active.value && isSceneReady.value);

    toggleHelpers(enableHelpers);

    const init = (assets) => {
        const { gltf } = assets;
        if (!gltf) console.warn('No GLTF found in assets');
        else {
            setupAnimations({ animations: gltf.animations, object: gltf.scene });
            if (addTo) scene.add(gltf.scene);
        }

        cameraScene = scene.getObjectByName('Camera');
        cameraDefault.fov = cameraScene?.fov || 10.548; // default camera scene fov
        // Recalculate the camera's internal matrices after changing properties
        // Required whenever we modify camera parameters (fov, aspect, near, far)
        cameraDefault.updateProjectionMatrix();

        if (enableHelpers) addCameraHelpers();

        isSceneReady.value = true;
        setOrbit(enableOrbit);
        cbSceneReady({ scene, camera, assets, animationsMap: getAnimations(), gltf });
        resize(viewport);
    };

    const resize = ({ width, height }) => {
        vViewOffset.y = width;
        vViewOffset.z = height;
        if (camera) {
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        }
    };

    const setCameraParameters = ({ zoom = null, viewOffset = null, camera = null } = {}) => {
        const _camera = camera;
        if (!_camera) return;

        if (viewOffset) {
            vViewOffset.w = viewOffset.x;
            vViewOffset.x = viewOffset.y;
            const { w: x, x: y, y: vw, z: vh } = vViewOffset;
            _camera?.setViewOffset(vw, vh, x, y, vw, vh);
        }
        if (zoom) _camera.zoom = zoom;
        _camera.updateProjectionMatrix();
    };

    // Frame loop control variables
    let renderActive = false; // Controls whether the frame loop is running
    let lastFrameTime = 0; // Timestamp of the last rendered frame

    /**
     * Main render loop function
     * Implements frame rate limiting for consistent performance
     * @param {number} timestamp Current time from requestAnimationFrame
     */
    function frameLoop(timestamp) {
        if (!renderActive) return;
        requestAnimationFrame(frameLoop);

        if (!canRender.value) {
            return; // Skip if scene is not active
        }

        // Calculate time since last frame
        const delta = timestamp - lastFrameTime;
        if (delta >= FRAME_INTERVAL) {
            // Update lastFrameTime while accounting for any remainder
            // This ensures smooth frame pacing
            lastFrameTime = timestamp - (delta % FRAME_INTERVAL);

            // 1) Update animations with current timing
            updateAnimations({ delta, timestamp });

            // 2) Render the scene
            render();
        }
    }

    function startFrameLoop() {
        if (!renderActive) {
            renderActive = true;
            lastFrameTime = performance.now();
            requestAnimationFrame(frameLoop);
        }
    }

    function stopFrameLoop() {
        renderActive = false;
    }

    const render = ({ force = false } = {}) => {
        if (!canRender.value && !force) return;

        renderer.clear();
        renderer.info.reset();

        if (composer && composer.type == 'pmndrs') {
            composer.render();

            renderer.clearDepth();
            orthoCamera.layers.set(LAYER_FG);
            renderer.render(scene, orthoCamera);
        } else {
            orthoCamera.layers.set(LAYER_BG);
            renderer.render(scene, orthoCamera);

            renderer.clearDepth();

            if (composer && composer.type == 'three') composer.render();
            else renderer.render(scene, camera);

            renderer.clearDepth();
            orthoCamera.layers.set(LAYER_FG);
            renderer.render(scene, orthoCamera);
        }
    };

    // ― getters / setters
    const getCamera = () => cameraScene;
    const getScene = () => scene;

    const setOrbit = (bool) => {
        // Initialize OrbitControls only when explicitly needed (bool=true)
        // since it adds expensive event listeners for mouse/touch interactions
        if (!orbit && bool) {
            orbit = new OrbitControls(camera, renderer.domElement);
        }
        camera = !bool && cameraScene ? cameraScene : cameraDefault;
        if (orbit) orbit.enabled = bool;

        if (composer) {
            const passes = composer.composer?.passes;
            if (passes) {
                const renderPass = passes.find((pass) => pass.name == 'RenderPassScene');
                if (renderPass) renderPass.camera = camera;
                else console.log('RenderPassScene not found in composer');
            }
        }

        resize(viewport);
    };

    onBeforeUnmount(() => {
        destroy();
        if (orbit instanceof OrbitControls) orbit.dispose();

        // Stop the animation frame loop to prevent memory leaks and unnecessary rendering
        // This ensures the scene stops consuming CPU/GPU resources when unmounted
        stopFrameLoop();
    });

    const destroy = () => {
        // disposeScene(scene);
    };

    useLoaderComplete(assets, ({ data }) => {
        init(data);
    });

    return {
        getCamera,
        getScene,

        getAnimation,
        getAnimations,

        setOrbit,
        setCameraParameters,

        toggleHelpers,
        refreshHelpers,

        scene,
        orbit,
        isSceneReady: isSceneReady,
        renderFn: render,
    };
};

export default useThreeMainScene;
